import React from 'react';
import { navigate } from 'gatsby-link';
import urls from 'helpers/url';
import { injectIntl } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    SharedStyles,
    Container,
    Fieldset,
    FieldsGroup,
    FieldSelect,
    FieldInput,
    FieldSubmit,
    FieldCheckbox,
} from '../../kenra-storybook/index';

const { StSectionInfo, StTitle } = SharedStyles;

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return [match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
}

const CarryUsForm = ({ intl }) => {
    const [state, setState] = React.useState({});

    const handleChange = e => {
        if (e.target.name === 'phone') {
            setState({
                ...state,
                [e.target.name]:
                    formatPhoneNumber(e.target.value) || e.target.value,
            });
        } else {
            setState({ ...state, [e.target.name]: e.target.value });
        }
    };

    const handleCheckboxChange = e => {
        var currentCheckboxState = state.carryus;
        if (
            currentCheckboxState === undefined ||
            currentCheckboxState.size === 0
        ) {
            currentCheckboxState = [];
            currentCheckboxState.push(e.target.name);
        } else {
            var indexOfCheckbox = currentCheckboxState.indexOf(e.target.name);
            if (indexOfCheckbox !== -1) {
                currentCheckboxState.splice(indexOfCheckbox, 1);
            } else {
                currentCheckboxState.push(e.target.name);
            }
        }
        setState({ ...state, carryus: currentCheckboxState });
    };

    const handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch('/.netlify/functions/submitToZendesk', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': 'carryus',
                emailsubject: 'Kenra Carry Us Form Submission',
                ...state,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 406) {
                        throw new Error(
                            intl.formatMessage({
                                id: 'form-submission-robot-error',
                            })
                        );
                    }
                    throw new Error(
                        intl.formatMessage({ id: 'form-submission-error' })
                    );
                }
            })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => {
                alert(error.message);
                document.getElementById('carryusform').reset();
            });
    };

    return (
        <>
            <Spacing removeSpaceBottom>
                <StSectionInfo>
                    <Container>
                        <StTitle colorBlack styleMedium>
                            <h2>
                                {intl.formatMessage({
                                    id: 'carry-us-form-join-us',
                                })}
                            </h2>
                        </StTitle>
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing>
                <Container useMediumWidth>
                    <form
                        id="carryusform"
                        name="carryus"
                        method="POST"
                        action={urls.submitted}
                        onSubmit={handleSubmit}
                    >
                        <p hidden>
                            <label>
                                Don’t fill this out:
                                <input
                                    name="bot-field"
                                    onChange={handleChange}
                                />
                            </label>
                        </p>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'form-salon-name-title',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="name-id-1"
                                label={intl.formatMessage({
                                    id: 'form-salon-name',
                                })}
                                name="salonName"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <FieldsGroup>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'form-email-title',
                                })}
                            >
                                <FieldsGroup>
                                    <FieldInput
                                        type="email"
                                        id="email-id-1"
                                        name="email"
                                        onChange={handleChange}
                                        required
                                        label={intl.formatMessage({
                                            id: 'form-email-address',
                                        })}
                                    />
                                </FieldsGroup>
                            </Fieldset>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'form-phone-title',
                                })}
                            >
                                <FieldInput
                                    type="text"
                                    id="num-id-1"
                                    label="###-###-####"
                                    size={12}
                                    name="phone"
                                    onChange={handleChange}
                                    required
                                />
                            </Fieldset>
                        </FieldsGroup>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'form-name-title',
                            })}
                        >
                            <FieldsGroup>
                                <FieldInput
                                    type="text"
                                    id="text-id-1"
                                    label={intl.formatMessage({
                                        id: 'form-first-name',
                                    })}
                                    name="firstName"
                                    onChange={handleChange}
                                    required
                                />
                                <FieldInput
                                    type="text"
                                    id="text-id-2"
                                    label={intl.formatMessage({
                                        id: 'form-last-name',
                                    })}
                                    name="lastName"
                                    onChange={handleChange}
                                />
                            </FieldsGroup>
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'form-address-title',
                            })}
                        >
                            <FieldsGroup>
                                <FieldInput
                                    type="text"
                                    id="address-id-1"
                                    label={intl.formatMessage({
                                        id: 'form-street',
                                    })}
                                    name="addressLine1"
                                    onChange={handleChange}
                                />
                                <FieldInput
                                    type="text"
                                    id="address-id-2"
                                    label={intl.formatMessage({
                                        id: 'form-city',
                                    })}
                                    name="addressLine2"
                                    onChange={handleChange}
                                />
                            </FieldsGroup>
                            <FieldsGroup>
                                <FieldInput
                                    type="text"
                                    id="address-id-3"
                                    label={intl.formatMessage({
                                        id: 'form-state-province',
                                    })}
                                    name="addressLine3"
                                    onChange={handleChange}
                                />
                                <FieldInput
                                    type="text"
                                    id="address-id-4"
                                    label={intl.formatMessage({
                                        id: 'form-country',
                                    })}
                                    name="addressCountry"
                                    onChange={handleChange}
                                />
                            </FieldsGroup>
                            <FieldsGroup>
                                <FieldInput
                                    type="text"
                                    id="address-id-4"
                                    label={intl.formatMessage({
                                        id: 'form-zip-code',
                                    })}
                                    name="addressZip"
                                    onChange={handleChange}
                                />
                            </FieldsGroup>
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'form-what-are-you-interested-in',
                            })}
                        >
                            <FieldsGroup>
                                <FieldCheckbox
                                    id="checkbox-id-1"
                                    name="Kenra"
                                    label={intl.formatMessage({
                                        id: 'form-kenra',
                                    })}
                                    onChange={handleCheckboxChange}
                                />
                                <FieldCheckbox
                                    id="checkbox-id-2"
                                    name="Kenra Color"
                                    label={intl.formatMessage({
                                        id: 'form-kenra-color',
                                    })}
                                    onChange={handleCheckboxChange}
                                />
                                <FieldCheckbox
                                    id="checkbox-id-3"
                                    name="Kenra Platinum"
                                    label={intl.formatMessage({
                                        id: 'form-kenra-platinum',
                                    })}
                                    onChange={handleCheckboxChange}
                                />
                            </FieldsGroup>
                        </Fieldset>
                        <FieldSubmit>
                            <input
                                value={intl.formatMessage({
                                    id: 'form-submit-button',
                                })}
                                type="submit"
                            />
                        </FieldSubmit>
                    </form>
                </Container>
            </Spacing>
        </>
    );
};

export default injectIntl(CarryUsForm);
