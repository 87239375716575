import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import CarryUsForm from './CarryUsForm';
import { injectIntl, useIntl } from 'gatsby-plugin-react-intl';

import { Spacing, Container, LinkImage } from '../../kenra-storybook/index';
import get from 'lodash.get';

function getItemData(title, text, link, image) {
    let intl = useIntl();
    return {
        title: title,
        text: text && documentToReactComponents(JSON.parse(text.raw || '{}')),
        btnText: intl.formatMessage({ id: 'learn-more-text' }),
        btnHref: link,
        img: get(image, 'localFile.image.resize.src'),
    };
}

const CarryUs = ({ intl, page }) => {
    let items = [
        getItemData(page.title1, page.text1, page.link1, page.image1),
        getItemData(page.title2, page.text2, page.link2, page.image2),
        getItemData(page.title3, page.text3, page.link3, page.image3),
    ];

    return (
        <>
            {Array.isArray(items) && items.length > 0 && (
                <Spacing removeSpaceTop removeSpaceBottom>
                    <Container>
                        <LinkImage items={items} />
                    </Container>
                </Spacing>
            )}

            <CarryUsForm />
        </>
    );
};

export default injectIntl(CarryUs);
