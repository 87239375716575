import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import CarryUs from 'components/CarryUs';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function CarryUsPage(props) {
    let {
        data: { contentfulCarryUsPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCarryUsPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulCarryUsPage, 'seoListing.seoTitle')}
                slug={'carry-us'}
            />
            <CarryUs page={contentfulCarryUsPage} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCarryUsPage(node_locale: { eq: $locale }) {
            title1
            text1 {
                raw
            }
            link1
            image1 {
                localFile {
                    publicURL
                    image: childImageSharp {
                        resize(width: 1200, height: 640) {
                            src
                        }
                    }
                }
            }
            title2
            text2 {
                raw
            }
            link2
            image2 {
                localFile {
                    publicURL
                    image: childImageSharp {
                        resize(width: 1200, height: 640) {
                            src
                        }
                    }
                }
            }
            title3
            text3 {
                raw
            }
            link3
            image3 {
                localFile {
                    publicURL
                    image: childImageSharp {
                        resize(width: 1200, height: 640) {
                            src
                        }
                    }
                }
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
        }
    }
`;
